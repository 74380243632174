export default interface UserBookStatusType {
  id: number;
  name: string;
  icon?: any;
  activeIcon?: any;
  size?: any;
  duoIcon: any;
}

const constants = {
  1: "Want to Read",
  2: "Currently Reading",
  3: "Read",
  4: "Paused",
  5: "Stopped",
  6: "Ignored",
};

const constantsActive = {
  1: "Wants to Read",
  2: "Is Currently Reading",
  3: "Finished Reading",
  4: "Paused Reading",
  5: "Stopped Reading",
  6: "Ignored",
};

const slugConstants = {
  1: "want-to-read",
  2: "currently-reading",
  3: "read",
  4: "paused",
  5: "did-not-finish",
  6: "ignored",
};

const slugIdConstants = {
  "want-to-read": 1,
  "currently-reading": 2,
  read: 3,
  paused: 4,
  "did-not-finish": 5,
  ignored: 6,
};

export { constants, slugConstants, slugIdConstants, constantsActive };
