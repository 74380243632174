import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import pick from "lodash/pick";
import { editFields } from "../constants";
import { UserBookType } from "types";
import { transformCamelToSnake } from "lib/stringFunctions";

const updateUserBookQuery = gql`
  mutation UpdateUserBook($id: Int!, $object: UserBookUpdateInput!) {
    updateResponse: update_user_book(id: $id, object: $object) {
      error
      userBook: user_book {
        ...UserBookFragment
      }
    }
  }
`;

export async function updateUserBook({
  client,
  id,
  userBook,
}: {
  client: ApolloClient<any>;
  id: number;
  userBook: UserBookType;
}): Promise<UserBookType> {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: updateUserBookQuery,
        variables: {
          id,
          object: pick(transformCamelToSnake(userBook), editFields),
        },
      })
      .then((result) => {
        if (result.data.updateResponse.error) {
          reject(result.data.updateResponse.error);
        } else {
          resolve(result.data.updateResponse.userBook);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}
