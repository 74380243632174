import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import pick from "lodash/pick";
import { editFields } from "../constants";
import { UserBookType } from "types";
import { transformCamelToSnake } from "lib/stringFunctions";

const insertUserBookQuery = `
  mutation CreateUserBook($object: UserBookCreateInput!) {
    insertResponse: insert_user_book(object: $object) {
      error
      userBook: user_book {
        ...UserBookFragment
      }
    }
  }
`;
const insertUserBookQueryCompiled = gql(insertUserBookQuery);

const insertFields = ["book_id", ...editFields];

interface CacheableUserBookType {
  userBook: UserBookType;
  query: string;
  variables: any;
  data: any;
}

export async function insertUserBook({
  client,
  userBook,
}: {
  client: ApolloClient<any>;
  userBook: UserBookType;
}): Promise<CacheableUserBookType> {
  const objectCamel = transformCamelToSnake(userBook);
  const object = pick(objectCamel, insertFields);

  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: insertUserBookQueryCompiled,
        variables: {
          object,
        },
      })
      .then((result) => {
        if (result.data.insertResponse.error) {
          reject(result.data.insertResponse.error);
        } else {
          resolve({
            userBook: result.data.insertResponse.userBook,
            data: result.data,
            variables: object,
            query: insertUserBookQuery,
          });
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}
