import { useSelector } from "react-redux";
import UserBookStatusFragmentCompiled from "queries/user_books/fragments/UserBookStatusFragmentCompiled";
import { userLoadingState } from "store/currentUser/currentUserSelector";
import { useFragment } from "@apollo/experimental-nextjs-app-support/ssr";
import useMount from "hooks/useMount";

export default function useBookButton(bookId) {
  const mounted = useMount();
  const { currentUserId, userLoaded } = useSelector(userLoadingState);

  const { data } = useFragment({
    fragment: UserBookStatusFragmentCompiled,
    fragmentName: "UserBookStatusFragment",
    from: {
      __typename: "user_books",
      bookId,
      userId: currentUserId,
    },
  });

  return {
    userBook: data?.userId
      ? data
      : {
          userId: currentUserId,
          bookId,
          statusId: 0,
        },
    loaded: userLoaded && mounted,
  };
}
