"use client";

import { DrawerSettingsType } from "features/Drawer/types/types";
import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "store/currentUser/currentUserSelector";

const BookButtonOpen = lazy(() => import("../BookButtonOpen"));

export default function BookButtonDrawer({
  bookId,
  settings,
}: {
  bookId: number;
  settings: DrawerSettingsType;
}) {
  const userId = useSelector(getUserId);

  // Never load or show the Book Button Drawer when not logged in
  if (!userId) {
    return <></>;
  }

  return (
    <Suspense>
      <BookButtonOpen bookId={bookId} settings={settings} />
    </Suspense>
  );
}
