"use client";

import { useEffect, useState } from "react";
import { useIsScreenSizeBiggerThanTailwindSize } from "hooks/useIsScreenSizeBiggerThanTailwindSize";
import BookButton, {
  Props,
  BookButtonSizeType,
} from "../BookButton/BookButton";

interface ResizeableBookButtonProps extends Props {
  sizeSm: BookButtonSizeType;
  sizeLg: BookButtonSizeType;
}

export default function ResizeableBookButton({
  bookId,
  referrerUserId,
  size = "md",
  placement,
  onClick,
  sizeLg,
  sizeSm,
}: ResizeableBookButtonProps) {
  const [buttonSize, setButtonSize] = useState<BookButtonSizeType>(size);
  const isLarge = useIsScreenSizeBiggerThanTailwindSize("lg");

  useEffect(() => {
    if (isLarge === null) {
      setButtonSize(size);
    } else {
      setButtonSize(isLarge ? sizeLg : sizeSm);
    }
  }, [isLarge]);

  return (
    <BookButton
      bookId={bookId}
      placement={placement}
      referrerUserId={referrerUserId}
      size={buttonSize}
      onClick={onClick}
    />
  );
}
