"use client";

/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
  ForwardedRef,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserBookStatusType } from "types";
import { STATUS_MAP } from "components/shared/UserBookStatuses";
import Icon from "hardcover-ui/components/Icon";
import faChevronDown from "icons/solid/chevron-down.svg";
import { uiActions } from "store/ui/uiSlice";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";
import { getBookDrawerStatus } from "store/bookButton/bookButtonSelector";
import classNames from "lib/classNames";
import StatusColors, {
  sharedButtonClasses,
} from "features/BookButton/constants/StatusColors";
import { buttonTheme } from "hardcover-ui/theme/components/button";
import { slugIdConstants } from "types/UserBookStatusType";
import { toast } from "sonner";
import { BookButtonSizeType } from "../BookButton";
import { insertUserBook } from "services/UserBookService/UserBookServiceClient";
import useCurrentUser from "hooks/fragments/useCurrentUser";

export default function StatusButton({
  el,
  loading,
  referrerUserId,
  size,
  userBook,
  onClick,
}: {
  el: ForwardedRef<HTMLDivElement>;
  loading: boolean;
  referrerUserId?: number;
  size: BookButtonSizeType;
  userBook: UserBookStatusType;
  onClick?: any;
}) {
  const uid = `book-button-${userBook.bookId}`;
  const dispatch = useDispatch();
  const defaultPrivacySettingId = useCurrentUser(
    (u) => u.accountPrivacySettingId
  );
  const [open, setOpen] = useState(false);
  const { open: isOpen, bookId } = useSelector(getBookDrawerStatus);

  const { status, buttonClasses, accentColor, fillColor, noStatus } =
    useMemo(() => {
      const statusColor = StatusColors[loading ? 0 : userBook.statusId];
      return {
        status: STATUS_MAP[userBook.statusId || 0],
        buttonClasses: statusColor.buttonClasses,
        accentColor: statusColor.accentColor,
        fillColor: statusColor.fillColor,
        noStatus: !loading && userBook.statusId === 0 && userBook.userId > 0,
      };
    }, [userBook.statusId, loading, userBook.userId]);

  // Only open if:
  //   Drawer is marked as open
  //   Drawer book matches this one
  useEffect(() => {
    if (open) {
      setOpen(isOpen && userBook.bookId === bookId);
    }
  }, [isOpen, bookId]);

  const markAsWantToRead = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (!userBook.userId) {
        // Not logged in, show login
        dispatch(uiActions.toggleLoginVisible("Login"));
        return;
      }
      if (userBook.statusId !== 0) {
        // Only allow new UserBooks to be created
        // Should never happen, but just in case
        return;
      }

      // Otherwise, add the UserBook. This will update cache and rerender this component
      insertUserBook({
        userBook: {
          userId: userBook.userId,
          bookId: userBook.bookId,
          statusId: slugIdConstants["want-to-read"],
          privacySettingId: defaultPrivacySettingId,
          referrerUserId,
        },
      })
        .then(({ userBook: newUserBook }) => {
          if (newUserBook) {
            toast.success("Saved as Want to Read.");
          }
        })
        .catch(() => {
          toast.error("Error saving book. Try again?");
        });
    },
    [userBook, referrerUserId]
  );

  return (
    <div
      ref={el}
      className={
        noStatus && size === "md"
          ? `${sharedButtonClasses} dark:border-gray-700 dark:bg-gray-700 font-medium overflow-hidden divide-x divide-gray-200 dark:divide-gray-600`
          : ""
      }
    >
      <button
        type="button"
        className={classNames(
          noStatus && size === "md"
            ? `space-x-2 py-2.5 px-3.5 ${buttonClasses} shadow-button active:shadow-none whitespace-nowrap`
            : classNames(
                `book-button ${uid}`,
                "space-x-2",
                sharedButtonClasses,
                buttonClasses,
                buttonTheme.size.lg
              )
        )}
        data-open={open ? "true" : "false"}
        onClick={noStatus && size === "md" ? markAsWantToRead : onClick}
      >
        <Icon icon={status.duoIcon} className={accentColor} size="lg" />

        {loading ? (
          <>
            <span
              className={classNames(accentColor, "font-semibold text-base")}
            >
              {size === "md" ? (
                <>Loading...</>
              ) : (
                <span className="invisible">.</span>
              )}
            </span>
            <LoadingSpinner size="sm" className={accentColor} />
          </>
        ) : (
          <>
            <span
              className={classNames(
                accentColor,
                "font-semibold text-base whitespace-nowrap"
              )}
            >
              {size === "md" ? (
                <>{status.name}</>
              ) : (
                <span className="invisible">.</span>
              )}
            </span>
            {!(noStatus && size === "md") ? (
              <Icon icon={faChevronDown} className={fillColor} />
            ) : (
              false
            )}
          </>
        )}
      </button>

      {/* Drop down button only for no-status */}
      {noStatus && size === "md" ? (
        <button
          type="button"
          className={`book-button ${uid} ${buttonClasses} py-2.5 px-3.5 shadow-button active:shadow-none`}
          data-open={open ? "true" : "false"}
          onClick={onClick}
        >
          <Icon icon={faChevronDown} className={fillColor} />
        </button>
      ) : (
        false
      )}
    </div>
  );
}
