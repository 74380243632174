import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getState = (state: RootState) => state;

export const getBookButtonChannel = createSelector(
  getState,
  (state) => state.bookButton
);

export const getBookDrawerState = createSelector(
  getBookButtonChannel,
  ({ bookId, referrerUserId }) => {
    return {
      bookId,
      referrerUserId,
      isOpen: bookId !== null,
    };
  }
);

export const getBookDrawerStatus = createSelector(
  getBookButtonChannel,
  ({ bookId }) => {
    return {
      open: bookId !== null,
      bookId,
    };
  }
);

export const getBookButtonBootstrapped = createSelector(
  getBookButtonChannel,
  ({ isLoaded }) => {
    return isLoaded;
  }
);

export const getUserBook = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook;
  }
);

export const getUserBookProgress = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    if (userBook?.datesRead?.length > 0) {
      return Math.round(
        userBook.datesRead[userBook.datesRead.length - 1].progress
      );
    }
    return null;
  }
);

export const getUserBookRead = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    if (userBook?.datesRead?.length > 0) {
      return userBook.datesRead[userBook.datesRead.length - 1];
    }
    return null;
  }
);

export const getUserBookRating = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.rating;
  }
);

export const getUserBookReviewed = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.hasReview;
  }
);

export const getDatesReadCount = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.datesRead?.length || 0;
  }
);

export const getReadingJournalCount = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.readingJournals?.length || 0;
  }
);

export const getDatesRead = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.datesRead || [];
  }
);

export const getUserBookNewRecord = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.id === null;
  }
);

export const getUserBookPrivacySettingId = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return userBook?.privacySettingId || 1;
  }
);

export const getIsStatusActive: (
  state: any,
  currentStatusId: number
) => boolean = createSelector(
  getBookButtonChannel,
  (_, currentStatusId: number) => currentStatusId,
  ({ userBook }, currentStatusId) => {
    return userBook?.statusId === currentStatusId;
  }
);

export const getHasEdits = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return (
      userBook?.rating ||
      userBook?.hasReview ||
      userBook?.privateNotes ||
      userBook?.readCount > 0
    );
  }
);

export const getisNewRecord = createSelector(
  getBookButtonChannel,
  ({ userBook }) => {
    return !(userBook?.id > 0);
  }
);

export const getTaggings = createSelector(
  getBookButtonChannel,
  ({ taggings }) => {
    return taggings;
  }
);

export const getListBooks = createSelector(
  getBookButtonChannel,
  ({ listBooks }) => {
    return listBooks;
  }
);
