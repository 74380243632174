"use client";

import { getClient } from "lib/apollo/client";
import {
  updateUserBook as updateUserBookService,
  insertUserBook as insertUserBookService,
  deleteUserBook as deleteUserBookService,
} from "./index";
import { UserBookType } from "types";

// UPDATE
export async function updateUserBook({
  id,
  userBook,
}: {
  id: number;
  userBook: UserBookType;
}) {
  const client = await getClient();
  return updateUserBookService({ client, id, userBook });
}

// INSERT
export async function insertUserBook({ userBook }: { userBook: UserBookType }) {
  const client = await getClient();
  return insertUserBookService({ client, userBook });
}

// Delete
export async function deleteUserBook(id: number) {
  const client = await getClient();
  return deleteUserBookService({ client, id });
}
