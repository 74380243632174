import { gql } from "graphql-tag";
import { ApolloClient } from "@apollo/client";

// Delete
const deleteUserBookQuery = gql`
  mutation DestroyUserBook($id: Int!) {
    deleteResponse: delete_user_book(id: $id) {
      id
      bookId: book_id
      userId: user_id
    }
  }
`;

export type DeleteUserBookResponseType = {
  id: number;
  bookId: number;
  userId: number;
};

export async function deleteUserBook({
  client,
  id,
}: {
  client: ApolloClient<any>;
  id: number;
}): Promise<DeleteUserBookResponseType> {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: deleteUserBookQuery,
        variables: {
          id,
        },
        update: (cache, response) => {
          const normalizedId = cache.identify({
            __typename: "user_books",
            userId: response.data.deleteResponse.userId,
            bookId: response.data.deleteResponse.bookId,
          });
          cache.evict({ id: normalizedId });
        },
      })
      .then((result) => {
        if (result.data.deleteResponse.id) {
          resolve(result.data.deleteResponse);
        } else {
          reject();
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}
