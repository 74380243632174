import snakeCase from "lodash/snakeCase";

export const editFields = [
  "edition_id",
  "review_has_spoilers",
  "first_started_reading_date",
  "last_read_date",
  "read_count",
  "status_id",
  "rating",
  "private_notes",
  "recommended_by",
  "recommended_for",
  "review",
  "privacy_setting_id",
  "review_slate",
  "referrer_user_id",
  "reviewed_at",
  "url",
  "media_url",
  "date_added",
  "sponsored_review",
];

export const transform = (ob) => {
  return Object.fromEntries(
    Object.entries(ob).map(([k, v]) => [snakeCase(k), v])
  );
};
