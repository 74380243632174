import snakeCase from "lodash/snakeCase";

export function kabobToTitle(string) {
  return string
    .split("-")
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function shorten(string, length) {
  if (string.length <= length) {
    return string;
  }
  return `${string.substr(0, length)}…`;
}

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp("^(https?:\\/\\/)?"); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const transformCamelToSnake = (ob) => {
  return Object.fromEntries(
    Object.entries(ob).map(([k, v]) => [snakeCase(k), v])
  );
};
