"use client";

import useBookButton from "features/BookButton/hooks/useBookButton";
/* eslint-disable import/no-cycle */
import StatusButton from "./StatusButton/StatusButton";
import { MouseEvent, Suspense, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui/uiSlice";
import { Drawer } from "features/Drawer";
import { BookButtonDrawer } from "features/BookButtonDrawer";
import { Placement } from "@popperjs/core";

export type BookButtonSizeType = "sm" | "md";
export interface Props {
  bookId: number;
  referrerUserId?: number;
  size?: BookButtonSizeType;
  onClick?: any;
  placement?: Placement;
}

export default function BookButton({
  bookId,
  referrerUserId,
  size = "md",
  onClick,
  placement = "bottom-start",
}: Props) {
  const bookButtonRef = useRef<HTMLDivElement>();
  const { loaded, userBook } = useBookButton(bookId);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const openDropdown = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
      }

      if (open) {
        setOpen(false);
      } else if (userBook.userId) {
        setOpen(true);
      } else {
        dispatch(uiActions.toggleLoginVisible("Login"));
        if (onClick) {
          onClick();
        }
      }
    },
    [userBook, referrerUserId, open]
  );

  return (
    <div>
      <StatusButton
        el={bookButtonRef}
        loading={!loaded}
        userBook={userBook}
        size={size}
        referrerUserId={referrerUserId}
        onClick={openDropdown}
      />

      {open && bookButtonRef.current ? (
        <Suspense
          fallback={
            <Drawer.Loading
              settings={{
                isOpen: true,
                onClose: () => {},
                ref: bookButtonRef.current,
                placement,
              }}
            />
          }
        >
          <BookButtonDrawer
            bookId={bookId}
            settings={{
              isOpen: true,
              onClose: openDropdown,
              ref: bookButtonRef.current,
              placement,
            }}
          />
        </Suspense>
      ) : (
        false
      )}
    </div>
  );
}
