export const sharedButtonClasses =
  "inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 border rounded-lg active:translate-y-1 transition-all w-fit";

const NoStatus = {
  buttonClasses:
    "dark:border-gray-700 dark:bg-gray-700 bg-white border-gray-100 dark:text-gray-400 text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 focus:ring-gray-200",
  accentColor: "text-gray-700 dark:text-gray-300",
  fillColor: "fill-gray-700 dark:fill-gray-300",
};

const WantToReadColor = {
  buttonClasses:
    "border-yellow-400 bg-yellow-400 text-gray-800 hover:bg-yellow-300 focus:ring-yellow-600",
  accentColor: "text-yellow-900",
  fillColor: "fill-yellow-900",
};

const CurrentlyReadingColor = {
  buttonClasses:
    "border-green-500 bg-green-500 text-green-900 hover:bg-green-600 focus:ring-green-700",
  accentColor: "text-green-900",
  fillColor: "fill-green-900",
};

const ReadColor = {
  buttonClasses:
    "border-green-400 bg-green-400 text-green-900 hover:bg-green-500 focus:ring-green-600",
  accentColor: "text-green-900",
  fillColor: "fill-green-900",
};

const StoppedColor = {
  buttonClasses:
    "dark:border-gray-700 border-gray-300 bg-gray-300 dark:bg-gray-700 text-gray-500 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-900 focus:ring-gray-600",
  accentColor: "dark:text-gray-400 text-gray-500",
  fillColor: "dark:fill-gray-400 fill-gray-500",
};

const StatusColors = [
  NoStatus,
  WantToReadColor,
  CurrentlyReadingColor,
  ReadColor,
  StoppedColor,
  StoppedColor,
  StoppedColor,
];

export default StatusColors;
